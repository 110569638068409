import * as React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import svg1 from "../images/illustration/1.svg"
import svg2 from "../images/illustration/2.svg"
import svg3 from "../images/illustration/3.svg"
import svg4 from "../images/illustration/4.svg"
import svg5 from "../images/illustration/5.svg"
import svg6 from "../images/illustration/6.svg"
import svg7 from "../images/illustration/7.svg"
import svg8 from "../images/illustration/8.svg"
import svg9 from "../images/illustration/9.svg"
import "./footer.css"

const Footer = () => {
  const { t } = useTranslation()

  return (
    <footer>
      <div className="primary">
        <h2>{t("FOOTER.TITLE")}</h2>
        <ul>
          <li>{t("FOOTER.ITEM_1")}</li>
          <li>{t("GLOBAL.TITLE")}</li>
          <li>{t("FOOTER.ITEM_2")}</li>
          <li>
            {t("FOOTER.ITEM_3")}{" "}
            <a href="mailto:borkoudvarhaz@gmail.com">borkoudvarhaz@gmail.com</a>
          </li>
          <li>
            {t("FOOTER.ITEM_4")}{" "}
            <a href="tel:+36 20 447 6671">+36 20 447 6671</a>
          </li>
        </ul>
      </div>
      <div className="secondary" aria-hidden>
        <img src={svg1} alt="wine-bottle" />
        <img src={svg2} alt="wine-cork" />
        <img src={svg3} alt="wine-glass" />
        <img src={svg4} alt="bottle-opener" />
        <img src={svg5} alt="grapes" />
        <img src={svg6} alt="barrel" />
        <img src={svg7} alt="cheese" />
        <img src={svg8} alt="demijohn" />
        <img src={svg9} alt="barrel" />
      </div>

      <p>{t("FOOTER.PHOTO_CREDIT")}</p>
      <p>{t("FOOTER.TRANSLATION_CREDIT")}</p>
    </footer>
  )
}

export default Footer
