import * as React from "react"
import "./navigation.scss"
import Store, { Nav } from "../store"
import Hamburger from "./hamburger"
import logo from "../images/logo-s.svg"
import { useTranslation } from "gatsby-plugin-react-i18next"

interface Props {}

const getClickHandler = (setMenuOpen: (isMenuOpen: boolean) => void) => (
  element?: Element
) => () => {
  setMenuOpen(false)
  if (element) {
    try {
      // @ts-ignore
      element.focus()
    } catch (e) {}
    return element.scrollIntoView({ behavior: "smooth", block: "start" })
  }
  return window.scrollTo({ top: 0, behavior: "smooth" })
}

const Header: React.FC<Props> = () => {
  const { t } = useTranslation()
  const {
    activeNav,
    sectionRooms,
    sectionFacilities,
    sectionGallery,
    sectionExtra,
    sectionContact,
  } = React.useContext(Store)
  const getClassName = (section: Nav) =>
    activeNav === section ? "active nav-item" : "nav-item"
  const [isScrolled, setIsScrolled] = React.useState(false)
  const [isMenuOpen, setMenuOpen] = React.useState(false)
  const handleClick = getClickHandler(setMenuOpen)

  const scrollListener = () => {
    !isMenuOpen && setIsScrolled(window.scrollY > 5)
  }

  React.useEffect(() => {
    window.addEventListener("scroll", scrollListener, { passive: true })
    scrollListener()
    return () => {
      window.removeEventListener("scroll", scrollListener)
    }
  }, [isMenuOpen])

  return (
    <header className={isScrolled ? "shrunk" : "mega"}>
      <nav className={`${isMenuOpen ? "isActive" : ""}`}>
        <ul>
          <li>
            <button
              className={getClassName("szobak")}
              onClick={handleClick(sectionRooms)}
            >
              <h2>{t("ROOMS.TITLE")}</h2>
            </button>
          </li>

          <li>
            <button
              className={getClassName("szolgaltatasok")}
              onClick={handleClick(sectionFacilities)}
            >
              <h2>{t("FACILITIES.TITLE")}</h2>
            </button>
          </li>
          <li aria-hidden>
            <button
              className={getClassName("galeria")}
              onClick={handleClick(sectionGallery)}
            >
              <h2>{t("GALLERY.TITLE")}</h2>
            </button>
          </li>

          <li>
            <button
              className={getClassName("borravalo")}
              onClick={handleClick(sectionExtra)}
            >
              <h2>{t("EXTRA.TITLE")}</h2>
            </button>
          </li>
          <li>
            <button
              className={getClassName("kapcsolat")}
              onClick={handleClick(sectionContact)}
            >
              <h2>{t("CONTACT.TITLE")}</h2>
            </button>
          </li>
        </ul>
        <Hamburger
          onClick={() => {
            setMenuOpen(!isMenuOpen)
          }}
          isOpen={isMenuOpen}
        />
      </nav>
      <button
        className={`logo ${isScrolled ? "shrunk" : "mega"}`}
        onClick={handleClick()}
      >
        <h1 id="title">{t("GLOBAL.TITLE")}</h1>
        <img src={logo} aria-labelledby="title" />
      </button>
    </header>
  )
}

export default Header
