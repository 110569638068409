import * as React from "react"

export type Nav =
  | ""
  | "szobak"
  | "szolgaltatasok"
  | "galeria"
  | "borravalo"
  | "kapcsolat"

interface IDefaultValue {
  activeNav: Nav
  setActiveNav: React.Dispatch<React.SetStateAction<Nav>>
  sectionMain?: Element
  sectionGallery?: Element
  sectionRooms?: Element
  sectionFacilities?: Element
  sectionExtra?: Element
  sectionContact?: Element
}
const defaultValue = {
  activeNav: "",
  setActiveNav: () => {},
  sectionMain: undefined,
  sectionGallery: undefined,
  sectionRooms: undefined,
  sectionFacilities: undefined,
  sectionExtra: undefined,
  sectionContact: undefined,
} as IDefaultValue

const Store = React.createContext(defaultValue)

export default Store
