/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

interface Props {
  lang?: string
  meta?: []
  title: string
  metaDescription: string
}
const SEO: React.FC<Props> = ({ meta = [], title, metaDescription }) => {
  const { i18n } = useTranslation()

  const { site, mainImage } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
        mainImage: file(relativePath: { eq: "DSC05593.jpg" }) {
          childImageSharp {
            fixed(width: 1024) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  return (
    <Helmet
      htmlAttributes={{
        lang: i18n.language,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:url`,
          content: site.siteMetadata.siteUrl,
        },
        {
          property: `og:image`,
          content: mainImage.childImageSharp.fixed.src,
        },
        {
          property: `og:image:alt`,
          content: title,
        },
        {
          property: `twitter:image`,
          content: mainImage.childImageSharp.fixed.src,
        },
        {
          property: `image`,
          content: mainImage.childImageSharp.fixed.src,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

export default SEO
