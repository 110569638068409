import * as React from "react"
import "./hamburger.css"

interface Props {
  isOpen: boolean
  onClick: (event: any) => void
}
const Hamburger = ({ onClick, isOpen }: Props) => {
  return (
    <button
      aria-label="open navigation"
      className={`hamburger hamburger--stand ${isOpen ? "is-active" : ""}`}
      type="button"
      onClick={onClick}
      tabIndex={0}
    >
      <span className="hamburger-box">
        <span className="hamburger-inner" />
      </span>
    </button>
  )
}

export default Hamburger
