import * as React from "react"
import Navigation from "./navigation"
import Footer from "./footer"
import "./layout.css"
import "../fonts/fonts.css"

interface Props {
  children: React.ReactNode
}

const Layout: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Navigation />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
